import React, { useContext, useEffect, useRef, useState } from "react";
import { SEOReactHelmet } from "../../../components/SEO/SEOReactHelmet";
import { useStateWithLocalStorage } from "../../../hooks/useStateWithLocalStorage/useStateWithLocalStorage";
import { OnboardingGenderPage } from "../../onboarding/pages";
import { SignUpPage, SignUpStep } from "../../../pages";
import { useNavigate } from "../../../components/Link/Link";
import { LoginContext } from "../../../components/LoginProvider/LoginProvider";
import PreviewMealPlanCard from "../../../components/MealPlanCard/PreviewMealPlanCard/PreviewMealPlanCard";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { GenerateMealPlan_generateMealplan } from "../../../types/generateMealplan";
import { GreenStar } from "../components/ThreeGoalsList";
import { PersonalizedPlanNavbar } from "../components/PersonalizedPlanNavbar";
import { intervalToDuration, addMinutes, subSeconds, isEqual } from "date-fns";
import { StaticImage } from "gatsby-plugin-image";
import { PaymentContext, PaymentProvider } from "../../sign-up-flow/contexts/PaymentProvider";
import { PaymentMethodSelect } from "../../sign-up-flow/components/PaymentMethodSelect";
import StripeSubscribeSection from "../../sign-up-flow/components/StripeSubscribeSection";
import PayPalSubscribeSection from "../../sign-up-flow/components/PayPalSubscribeSection";
import { PaymentError } from "../../sign-up-flow/components/PaymentError";
import { isBrowser } from "../../../utils/ssr";

const PersonalizedPlanSalesPage = () => {
  return (
    <PaymentProvider>
      <PersonalizedPlanSalesPageContent />
    </PaymentProvider>
  );
};

// ts-prune-ignore-next
export default PersonalizedPlanSalesPage;

// Main content
function PersonalizedPlanSalesPageContent() {
  const { locale } = useContext(pageContext);
  const { loading: isUserLoading, user } = useContext(LoginContext);

  const { selectedPlan } = useContext(PaymentContext);

  const navigate = useNavigate();

  const paymentDetailsRef = useRef<HTMLDivElement>(null);

  const [anonMealPlan] = useStateWithLocalStorage<string | undefined>(
    "dd/onboarding/anonymous-meal-plan",
    undefined
  );

  useEffect(() => {
    if (!isUserLoading) {
      if (!anonMealPlan) {
        navigate({
          to: OnboardingGenderPage,
        });
      }
    }
  }, [anonMealPlan, isUserLoading, navigate]);

  useEffect(() => {
    if (isBrowser()) {
      window.localStorage.setItem("selectedPlan", JSON.stringify("ANNUAL"));
    }
  }, []);

  return (
    <>
      <SEOReactHelmet noindex title="Diet Doctor - Meal Plan Sales" lang={locale as string} />

      {/* Navbar */}
      <PersonalizedPlanNavbar />

      {/* Success state */}
      {typeof anonMealPlan !== "undefined" && (
        <main>
          {/* Heading */}
          <section className="bg-blue-500 py-6 px-3 text-left sm:text-center">
            <h2 className="text-2xl text-[1.6rem] text-white font-medium">Start your 7-day free trial</h2>
          </section>

          {/* Goals */}
          <section className="flex flex-col items-center text-center max-w-7xl mx-auto px-4 mt-8">
            <ul className="list-none text-left text-lg max-w-xs sm:max-w-sm p-0 m-0">
              <li className="flex flex-row items-start space-x-2">
                <GreenStar />
                <span>Reach your healthy weight</span>
              </li>

              <li className="flex flex-row items-start space-x-2 mt-2">
                <GreenStar />
                <span>Create healthy habits to maintain your results long term</span>
              </li>
              <li className="flex flex-row items-start space-x-2 mt-2">
                <GreenStar />
                <span>Find which foods work for you</span>
              </li>
            </ul>
          </section>

          {/* FOMO */}
          <section className="max-w-md sm:max-w-xl mt-8 mx-auto flex flex-col items-center text-center">
            <div className="bg-blue-200 rounded-lg mx-4 p-6">
              <h3 className="text-base uppercase -mx-3">Personalized meal plan reserved</h3>
              <p className="max-w-sm sm:max-w-xl mx-auto text-base px-4">
                Your personalized meal plan will be saved for the next 15 minutes.
              </p>
              <p className="text-base font-medium mt-2">TIME REMAINING:</p>
              <PersonalizedSalesPageCountdown />
            </div>
          </section>

          {/* Meal plan preview */}
          <section className="mt-4 max-w-4xl mx-auto flex flex-col items-center text-center">
            <div className="p-4 my-4 mx-8 border-4 border-blue-200 rounded-lg">
              <PreviewMealPlanCard
                mealPlan={JSON.parse(anonMealPlan) as GenerateMealPlan_generateMealplan}
                errorMessage={"Oops! We can't accommodate all your preferences yet."}
              />
            </div>
          </section>

          {/* How it works */}
          <section className="bg-blue-200 flex-col items-center w-full mx-auto mt-12 pt-12 pb-24">
            <h3 className="text-3xl text-center mx-auto px-3 max-w-xs">How your 7-day free trial works</h3>

            <div className="flex flex-col items-left text-left mt-6 mx-auto max-w-sm px-2">
              <div className="flex flex-row items-center">
                <StaticImage
                  src="../../../../static/images/onboarding-AB-test/check-circle.png"
                  placeholder="none"
                  height={64}
                  objectFit="contain"
                  alt="check"
                  className="shrink-0"
                />
                <div className="flex flex-col ml-3">
                  <h4 className="text-xl line-through text-[#2D5060]">Create your meal plan</h4>
                  <p className="text-lg">You’ve successfully created your personalized meal plan.</p>
                </div>
              </div>

              <div className="border-l-[3px] h-[4.5rem] -my-4 ml-[30.5px] border-[#2D5060]" />

              <div className="flex flex-row items-center">
                <StaticImage
                  src="../../../../static/images/onboarding-AB-test/lock-circle.png"
                  placeholder="none"
                  height={64}
                  objectFit="contain"
                  alt="lock"
                  className="shrink-0"
                />
                <div className="flex flex-col ml-3">
                  <h4 className="text-xl">Activate your free trial</h4>
                  <p className="text-lg">Create your account and choose your membership plan.</p>
                </div>
              </div>

              <div className="border-l-[3px] h-[4.5rem] -mt-4 -mb-5 ml-[30.5px] border-[#C6C6C6]" />

              <div className="flex flex-row items-center">
                <StaticImage
                  src="../../../../static/images/onboarding-AB-test/food-circle.png"
                  placeholder="none"
                  height={64}
                  objectFit="contain"
                  alt="food"
                  className="shrink-0"
                />
                <div className="flex flex-col ml-3">
                  <h4 className="text-xl">Start your journey with us</h4>
                  <p className="text-lg">
                    Cancel at any time in the next 7 days if you feel like it isn’t for you.
                  </p>
                </div>
              </div>
            </div>

            {/* CTA */}
            <div className="-mt-2">
              <PersonalizedSalesPageButton />
            </div>
          </section>

          {/* Plan & Payment */}

          {/* Plan selection and info */}
          <section className="max-w-lg px-4 mx-auto mt-8">
            <div className="text-center">
              <h3 className="text-3xl" id="your-membership">
                Start your 7-day<br></br>free trial
              </h3>
              <p className="mt-2">No commitment. Cancel anytime.</p>
            </div>

            <div className="max-w-sm mx-auto flex flex-col mt-[18px]">
              <span className="my-1.5 font-medium">PRICE TODAY</span>
              <hr className="w-full border-b border-t-0 opacity-50" />
              <div className="flex flex-row justify-between my-1.5">
                <span>7-day free trial</span>
                <div>
                  <span className="strikethrough-diagonal opacity-50 inline-block mr-2">
                    {selectedPlan === "ANNUAL" ? "119$" : "$14.99"}
                  </span>
                  <span>0$</span>
                </div>
              </div>
              <hr className="w-full border-b border-t-0 opacity-50" />
              <div className="flex flex-row items-center space-x-2 m-3">
                <GreenStar />
                <span>
                  You will have 1 month to see how <span className="whitespace-nowrap">Diet Doctor</span>{" "}
                  creates sustainable results
                </span>
              </div>

              <span className="my-1.5 mt-1.5 font-medium">PRICE AFTER TRIAL</span>
              <hr className="w-full border-b border-t-0 opacity-50" />

              <div className="flex flex-row justify-between my-1.5">
                <span>{selectedPlan === "ANNUAL" ? "Annual membership" : "Monthly membership"}</span>
                <div>
                  <span>${selectedPlan === "ANNUAL" ? "9.92" : "14.99"}/month</span>
                </div>
              </div>

              <div
                className={`flex flex-row items-center justify-between ${
                  selectedPlan === "ANNUAL" ? "" : "invisible"
                }`}
              >
                <div className="bg-green p-1 px-3 text-white text-center rounded-full">Discount applied</div>
                <span>billed at US$119/year</span>
              </div>
            </div>
          </section>

          {/* Plan & Payment */}
          <section className="max-w-lg px-4 mx-auto mt-16 overflow-x-hidden">
            <h3 className="text-center text-3xl">Payment details</h3>
            <p className="mt-2 text-center text-lg max-w-[256px] mx-auto">
              You will not be charged today for your 7-day free trial
            </p>
            {/* Error box */}
            <PaymentError />

            {/* Select payment method */}
            <div className="m-0 mt-8 body-l font-medium" ref={paymentDetailsRef}>
              {"Set up payment for later:"}
            </div>
            <PaymentMethodSelect
              debitCreditText={"Debit/credit"}
              paypalText={"PayPal"}
              scrollToRef={paymentDetailsRef}
            />

            {/* Payment method input, PayPal & Stripe */}
            <div ref={paymentDetailsRef} className="mb-24">
              {user?.userId && (
                <>
                  <StripeSubscribeSection updateName={true} />
                  <PayPalSubscribeSection updateName={true} />
                </>
              )}
            </div>
          </section>
        </main>
      )}
    </>
  );
}

// Misc components
function PersonalizedSalesPageButton() {
  const buttonClassNames =
    "block shadow-md hover:shadow-none bg-green px-8 py-4 text-lg mx-auto rounded-lg text-white text-xl hover:underline focus:opacity-95 transition-all font-medium w-[335px] text-center mt-12";

  return (
    <a className={buttonClassNames} href="#your-membership">
      {"Start 7-day free trial"}
    </a>
  );
}

function PersonalizedSalesPageCountdown() {
  const [duration, setDuration] = useState<Duration>({ minutes: 15, seconds: 0 });

  useEffect(() => {
    const start = new Date();
    let end = addMinutes(new Date(), 15);
    const interval = setInterval(() => {
      if (!isEqual(start, end)) {
        end = subSeconds(end, 1);
        const currentDuration = intervalToDuration({
          start: start,
          end: end,
        });
        setDuration(currentDuration);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <span className="text-lg inline-block -mt-2">
      {duration.minutes?.toLocaleString(undefined, { minimumIntegerDigits: 2 })}:
      {duration.seconds?.toLocaleString(undefined, { minimumIntegerDigits: 2 })}
    </span>
  );
}
